<template>
  <div
    v-if="type === 'flyer' || type === 'provider'"
    class="requestsDashboard__list js-request-list"
  >
    <div class="requestsDashboard__empty js-request-empty">no requests</div>
    <div class="requestsDashboard__list-header">
      <div class="requestsDashboard__list-header-item" data-cell="1">Date</div>
      <div class="requestsDashboard__list-header-item" data-cell="2">Model</div>
      <div class="requestsDashboard__list-header-item" data-cell="3">From</div>
      <div class="requestsDashboard__list-header-item" data-cell="4">To</div>
      <div class="requestsDashboard__list-header-item" data-cell="5">Outright Price</div>
      <div class="requestsDashboard__list-header-item" data-cell="6">Requested FLY Empty Price</div>
    </div>
    <vue-scroll :ops="ops">
      <div class="requestsDashboard__list-content js-customScroll">
        <div v-for="item of requests" :key="item.id" class="requestsDashboard__list-item js-request">
          <div class="requestsDashboard__list-item-inner">
            <div class="requestsDashboard__list-item-column" data-cell="1">
              <div class="requestsDashboard__list-item-title"> {{moment(item.flightDate).format('M/D/YYYY')}}</div>
            </div>
            <div class="requestsDashboard__list-item-column" data-cell="2">
              <div class="requestsDashboard__list-item-title">{{item.model}}</div>
            </div>
            <div class="requestsDashboard__list-item-column" data-cell="3">
              <div class="requestsDashboard__list-item-title">{{item.departure}}</div>
            </div>
            <div class="requestsDashboard__list-item-column" data-cell="4">
              <div class="requestsDashboard__list-item-title">{{item.arrival}}</div>
            </div>
            <div class="requestsDashboard__list-item-column" data-cell="5">
              <div class="requestsDashboard__list-item-title">{{ localePrice(item.outrightPrice) }}</div>
            </div>
            <div class="requestsDashboard__list-item-column" data-cell="6">
              <div class="requestsDashboard__list-item-title">{{ localePrice(item.flyEmptyPrice) }}</div>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>

    <QuestionPopup
      :name="'QuestionPopupRequest'"
      :title="'The request will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </div>
</template>

<script>
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import moment from 'moment'
  import './RequestsDashboardList.styl'

  export default {
    props: ['miniTab', 'type'],
    name: 'RequestsDashboardList',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      options: [],
      departure: '',
      arrival: '',
      date: '',
      dateFormat: 'M/D/YYYY',
      currentRequest: null,
    }),
    computed: {
      requests () {
        return this.$store.state.requests.requests
      },
    },
    async created () {
      await this.$store.dispatch('getRequests')
    },
    mounted () {
      this.$root.fields.changeChecked('.form__checkbox')
    },
    methods: {
      moment,
      submitHandler (event) {
        event.target.classList.add('loading')
      },
      localePrice (price) {
        return price.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
      },
      showModal (item) {
        this.$modal.show('QuestionPopupRequest')
        this.currentRequest = item
      },
      async remove () {
        // this.$store.commit('removeItemRequest')
        await this.$store.dispatch('deleteRequest', this.currentRequest)
      },
    },
    components: {
      QuestionPopup,
    },
  }
</script>
